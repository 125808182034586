import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { customActionDel, customActionGet } from '@core/utils/utils'

const endpoint = () => 'orders/purchases'

const Actions = crudActions(endpoint)
// eslint-disable-next-line no-empty-pattern
const getCustomer = ({ }, { id, queryParams = {} }) => customActionGet(`customers/${id}`, queryParams)
const stockItems = ({ commit }, queryParams) => customActionGet('catalog/stocks', {
  ...queryParams,
  f: {
    rules: {
      ...queryParams.f?.rules,
      can_be_procured: 1,
    },
    is_active: 1,
  },
}, commit, 'STOCK_ITEMS')
const otherChargesItems = ({ commit }, queryParams) => customActionGet('catalog/non-stocks', {
  ...queryParams,
  f: {
    is_active: 1,
  },
}, commit, 'SET_OTHER_CHARGES_ITEMS_LIST')
const createByID = (ctx, { id, data }) => axios.post(`/${endpoint()}/${id}`, data)
const createNote = (ctx, data) => axios.post('/orders/credit-note', data)
const uploadInvoice = (ctx, data) => axios.post(`/${endpoint()}/${data.get('id')}/invoice`, data)

const addToNewQuoteProductId = ({ }, { id, queryParams = {} }) => customActionGet(`products/${id}`, queryParams)

export default {
  ...Actions,
  stockItems,
  createByID,
  createNote,
  getCustomer,
  uploadInvoice,
  otherChargesItems,
  addToNewQuoteProductId,
  'send-by-action': (ctx, data) => axios.patch(`${endpoint()}/${data.id}`, data),
}
